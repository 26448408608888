const trans_ar_EG = {
  // Form Fields
  name: "الأسم",
  first_name: "الاسم الأول",
  last_name: "الاسم الأخير",
  name_ar: "الاسم العربي",
  name_en: "الاسم الانجليزي",
  price: "السعر",
  email: "البريد الالكتروني",
  password: "الرقم السري",
  confirm_password: "تاكيد الرقم السري",
  phone_number: "رقم الهاتف",
  business_name: "أسم المنشأة",
  business_type: "نشاط المنشأة",
  shop_address: "عنوان المنشأة",
  cr_number: "رقم السجل التجاري/ وثيقة العمل الحر",
  vat_number: "رقم ضريبة القيمة المضافة",
  business_logo: "شعار المنشأة",
  image: "صورة",
  is_taxable: "خاضع للضريبه",
  is_refunded: "يتم استرداد المبلغ",
  is_QRproduct: "هو منتج QR",
  is_stockable: "قابل للتخزين",
  reset: "إعادة الضبط",
  cart: "عربة التسوق",
  address: "العنوان",
  company: "شركة",
  individual: "فردي",
  code: "رمز",
  branch: "الفرع",
  active: "نشيط",
  inactive: "غير نشط",
  subscribe: "اشترك",
  unsubscribe: "ألغى الاشتراك",
  no_of_employees: "عدد الموظفين",
  no_of_licenses: "عدد التراخيص",
  vat_certificate: "شهادة ضريبة القيمة المضافة",
  cr_certificate: "شهادة السجل التجاري",
  change: "يتغير",
  preview: "معاينة",
  add_license: "إضافة ترخيص",
  purchase_license: "رخصة شراء",
  add_free_license: "إضافة رخصة مجانية",
  buy_more_license: "شراء المزيد من التراخيص!",
  buy_more_license_error: "شراء المزيد من الترخيص لإنشاء المزيد من المستخدمين.",
  buyer_company_name: "اسم شركة المشتري",
  buyer_company_vat: "رقم ضريبة القيمة المضافة لشركة المشتري",
  city: "المدينة",
  state: "المنطقة",
  country: "الدولة",
  postal_code: "الرمز البريدي",
  calculations: "العمليات الحسابية",
  description: "وصف",
  attachment: "المرفق",
  no_attachment: "لا يوجد مرفق",
  url: "URL",
  secret_key: "المفتاح السري",
  integration_type: "نوع التكامل",
  customer_name: "اسم الزبون",
  vehicle_number: "عدد المركبات",
  vehicle_color: "لون السيارة",
  reseller_number: "رقم الموزع",
  type: "يكتب",
  street: "شارع",
  building_number: "رقم المبنى",
  plot_id_number: "رقم معرف قطعة الأرض",

  // Form Validations
  validate_preferred_contact_time_required: "الرجاء اختيار وقت الاتصال المفضل.",
  validate_email_required: "حقل البريد الالكتروني مطلوب.",
  validate_email_invalid: "البريد لإلكتروني غير صحيح.",
  validate_password_required: "حقل كلمة المرور مطلوب",
  validate_password_min_characters: "يجب أن لا تقل كلمة المرور عن 6 أحرف.",
  validate_password_confirm_not_matched: "تأكيد كلمة المرور غير متطابقه",
  validate_name_required: "حقل الأسم مطلوب.",
  validate_name_min_characters: "يجب ألا يقل الاسم عن 3 أحرف.",
  validate_first_name_required: "مطلوب حقل الاسم الأول.",
  validate_first_name_min_characters: "يجب ألا يقل الاسم الأول عن 3 أحرف.",
  validate_last_name_required: "حقل الاسم الأخير مطلوب.",
  validate_last_name_min_characters: "يجب ألا يقل اسم العائلة عن 3 أحرف.",
  validate_phone_required: "حقل رقم الهاتف مطلوب",
  validate_phone_exact_limit: "يجب أن يكون رقم الهاتف 12 رقمًا بالضبط.",
  validate_business_name_required: "حقل أسم المنشاة مطلوب.",
  validate_business_type_required: "الرجاء تحديد نشاط المنشاة.",
  validate_cr_number_required: "مطلوب حقل CR #.",
  validate_cr_number_exact_limit:
    "يجب أن يكون رقم السجل التجاري من 10 أرقام بالضبط.",
  validate_vat_number_required: "حقل رقم ضريبة القيمة المضافة مطلوب.",
  validate_vat_number_exact_limit:
    "يجب أن يكون رقم ضريبة القيمة المضافة 15 رقمًا بالضبط.",
  validate_city_required: "حقل مدينة مطلوب",
  validate_state_required: "حقل حالة مطلوب",
  validate_country_required: "حقل البلد مطلوب",
  validate_post_code_required: "مطلوب حقل الرمز البريدي",
  validate_address: "حقل العنوان مطلوب.",
  validate_shop_address_required: "يرجى إدخال عنوان الخاص بك للتسوق!",
  validate_image_file_type: "يمكنك فقط تحميل ملف JPG / PNG!",
  validate_image_size: "يجب أن يكون حجم الصورة أصغر من 2 ميغا بايت!",
  validate_business_logo_required: "مطلوب شعار الشركة!",
  validate_image_pdf_file_type: "يمكنك فقط تحميل ملف JPG / PNG أو PDF!",
  validate_image_size_10: "يجب أن يكون حجم الصورة أصغر من 10 ميغا بايت!",
  validate_cr_certificate_required: "مطلوب شهادة السجل التجاري!",
  validate_vat_certificate_required: "مطلوب شهادة ضريبة القيمة المضافة!",
  validate_type_required: "حقل النوع مطلوب",
  validate_excel_file_type: "يمكنك فقط تحميل ملف Excel!",
  validate_file_size_1: "يجب أن يكون الملف أصغر من 1 ميغا بايت!",
  validate_attachment_required: "حقل المرفقات مطلوب!",

  validate_product_name_required: "الرجاء إدخال اسم المنتج!",
  validate_product_price_required: "الرجاء إدخال سعر المنتج!",
  validate_product_barcode_required: "الرجاء إدخال الرمز الشريطي للمنتج!",
  validate_product_category_required: "الرجاء تحديد فئة المنتج!",
  validate_product_unit_required: "الرجاء تحديد وحدة المنتج!",
  validate_address_required: "يرجى إدخال عنوان المتجر!",
  validate_branch_required: "الرجاء تحديد الفرع!",

  validate_description_required: "حقل الوصف مطلوب.",

  validate_cash_required: "الحقل النقدي مطلوب.",
  validate_card_required: "حقل البطاقة مطلوب.",
  validate_reference_required: "حقل الرقم المرجعي مطلوب.",
  validate_discount_required: "نسبة الخصم مطلوبة.",

  validate_url_required: "حقل URL مطلوب.",
  validate_url_invalid: "الإدخال ليس عنوان URL صالحًا!",
  validate_secret_key_required: "حقل المفتاح السري مطلوب.",
  validate_integration_type_required: "نوع التكامل مطلوب.",
  validate_date_required: "حقل التاريخ مطلوب.",
  validate_customer_name_required: "حقل اسم العميل مطلوب.",
  validate_vehicle_number_required: "حقل رقم المركبة مطلوب.",
  validate_vehicle_color_required: "حقل لون المركبة مطلوب.",

  validate_street_required: "حقل الشارع مطلوب.",
  validate_building_number_required: "حقل رقم المبنى مطلوب.",
  validate_plot_id_number_required: "حقل رقم معرف قطعة الأرض مطلوب.",
  validate_entity_required: "هذه الخانة مطلوبه.",

  // Login
  dont_have_account: "لا يوجد لديك حساب؟",
  login: "تسجيل الدخول",
  visit_website: "زيارة الموقع",

  // Register
  already_have_an_account: "لديك حساب بالفعل؟",
  sign_up: "التسجيل",
  register_successfully: "لقد تم تسجيلك بنجاح!",
  selected_plan: "الخطة المختارة",
  account_successfully_created: "تم إنشاء حسابك بنجاح!",
  details_activate_company: "املأ التفاصيل لتفعيل شركتك أو تخطي الآن.",

  // Forgot
  forgot: "نسيت الرقم السري",
  forgot_password: "هل نسيت الرقم السري؟",
  send_otp: "أرسل كلمة المرور لمرة واحدة",
  verify_otp: "التحقق من كلمة المرور",
  reset_password: "إعادة تعيين الرقم السري",
  reset_password_successfully: "تم إعادة تعيين الرقم السري بنجاح!",

  // Pricing
  pricing_plan: "خطة التسعير",
  number_of_users: "عدد المستخدمين",
  pricing: "التسعير",
  daily: "يوميًا",
  monthly: "شهريا",
  yearly: "سنوي",
  trial: "محاكمة",
  pro: "برو",
  sign_up_free_trial: "اشتراك مجاني لمدة سنة واحدة",
  pay: "يدفع",
  VAT_once_trial_period_is_over:
    "ضريبة القيمة المضافة بمجرد انتهاء الفترة التجريبية",
  month_per_user: "الشهر (السعر لكل مستخدم)",
  day_per_user: "اليوم (السعر لكل مستخدم)",
  for_1_user: "لمستخدم واحد",

  // otp form
  opt_message:
    "تم إرسال رسالة مع رمز OTP على رقم هاتفك المحمول. يرجى ملء الحقل أدناه والتحقق من صحة.",
  if_you_want_to_change_phone_number: "إذا كنت تريد تغيير رقم الهاتف",
  click_here: "انقر هنا.",
  resend_code: "أعد إرسال الرمز",
  enable_in: "تمكين في",
  validate: "تحقق",

  // Store Configuration
  beauty_and_personal_care: "الجمال و العناية الشخصية",
  retail_food_drinks: "تجارة التجزئة - أغذية - مشروبات",
  healthcare_and_fitness: "الرعاية الصحية واللياقة البدنية",
  home_and_repair: "الاصلاح والصيانة المنزلية",
  entertainment: "الترفيه",
  professional_services: "الخدمات المهنية",
  transportation: "النقل",
  shop_configuration_successfully: "اكتمل تكوين المتجر.",
  allow_round_off: "اسمح بالتقريب إلى رقمين",
  preferred_contact_time: "وقت الاتصال المفضل",

  // Menus
  menu_home: "الصفحة الرئيسية",
  menu_pos: "نقاط البيع",
  menu_products_list: "قائمة المنتجات",
  menu_products: "المنتجات",
  menu_categories: "الفئات",
  menu_units: "الوحدات",
  menu_transactions: "الفواتیر",
  menu_reports: "التقارير",
  menu_sales_summary: "ملخص المبيعات",
  menu_sales_by_items: "المبيعات حسب المنتجات",
  menu_sales_by_categories: "المبيعات حسب الفئات",
  menu_refunds_by_items: "المبالغ المعادة حسب العناصر",
  menu_refunds_by_categories: "المبالغ المعادة حسب الفئات",
  menu_report_by_branch: "تقرير حسب الفرع",
  menu_store_configuration: "تكوين المتجر",
  menu_subscriptions: "الاشتراكات",
  menu_branches: "الفروع",
  menu_orders: "طلبات",
  menu_view_order: "مشاهدة الطلب",
  menu_edit_order: "تحرير الطلب",
  menu_tables: "طاولات",
  menu_employees: "الموظفين",
  menu_invoices: "الفواتير",
  menu_devices: "الأجهزة",
  menu_help_desk: "الدعم الفني",
  menu_discounts: "الخصومات",
  menu_external_integration: "التكامل الخارجي",
  menu_addons: "إضافات",
  menu_addon_detail: "تفاصيل الملحق",
  menu_inventory: "جرد",
  menu_stock: "مخزون",
  menu_stock_adjustment: "التعديل الأسهم",
  menu_view_stock_adjustment: "عرض تعديل المخزون",
  menu_stock_transfer: "تحويل المخزن",
  menu_view_transfer: "عرض النقل",
  menu_edit_transfer: "تحرير النقل",
  menu_customers: "عملاء",
  menu_customer_details: "تفاصيل العملاء",
  menu_sales_invoice: "فاتورة المبيعات",
  menu_view_sales_invoice: "عرض فاتورة المبيعات",

  // Products
  product_add: "أضف المنتج",
  product_added: "تمت إضافة المنتج",
  product_added_successfully: "تمت إضافة المنتج بنجاح!",
  product_edit: "تحرير المنتج",
  product_updated: "تم تحديث المنتج",
  product_updated_successfully: "تم تحديث المنتج بنجاح!",
  product_deleted: "المنتج محذوف",
  product_deleted_successfully: "تم حذف المنتج بنجاح!",
  products_import: "استيراد المنتجات",
  products_import_successfully: "تم استيراد المنتجات بنجاح!",

  // Product Categories
  category_add: "إضافة فئة",
  category_name: "اسم الفئة",
  category_edit: "تحرير الفئة",
  category_name_required: "الرجاء إدخال اسم الفئة الخاصة بك!",
  category_added: "تمت إضافة الفئة",
  category_added_successfully: "تمت إضافة الفئة بنجاح!",
  category_updated: "تم تحديث الفئة",
  category_updated_successfully: "تم تحديث الفئة بنجاح!",
  category_deleted: "الفئة محذوفة",
  category_deleted_successfully: "تم حذف الفئة بنجاح!",
  categories_import: "فئات الاستيراد",
  categories_import_successfully: "تم استيراد الفئات بنجاح!",

  // Product Units
  unit_add: "إضافة وحدة",
  unit_name: "إسم الوحدة",
  Unit_edit: "تحرير الوحدة",
  unit_name_required: "الرجاء إدخال اسم الوحدة الخاصة بك!",
  unit_added: "تمت إضافة الوحدة",
  unit_added_successfully: "تمت إضافة الوحدة بنجاح!",
  unit_updated: "تم تحديث الوحدة",
  unit_updated_successfully: "تم تحديث الوحدة بنجاح!",
  unit_deleted: "تم حذف الوحدة",
  unit_deleted_successfully: "تم حذف الوحدة بنجاح!",
  units_import: "وحدات الاستيراد",
  units_import_successfully: "تم استيراد الوحدة بنجاح!",

  // Branches
  branch_add: "اضافة فرع",
  branch_added: "تمت إضافة الفرع",
  branch_added_successfully: "تمت إضافة الفرع بنجاح!",
  branch_deleted: "تم حذف الفرع",
  branch_deleted_successfully: "تم حذف الفرع بنجاح!",
  branch_edit: "تحرير الفرع",
  branch_updated: "فرع محدث",
  branch_updated_successfully: "تم تحديث الفرع بنجاح!",

  // Customers
  customer_add: "إضافة العميل",
  customer_added: "تمت إضافة العميل",
  customer_added_successfully: "تمت إضافة العميل بنجاح!",
  customer_deleted: "تم حذف العميل",
  customer_deleted_successfully: "تم حذف العميل بنجاح!",
  customer_edit: "تحرير العميل",
  customer_updated: "تم تحديث العميل",
  customer_updated_successfully: "تم تحديث العميل بنجاح!",

  // Orders
  mark_as_in_progress: "وضع علامة قيد التقدم",
  mark_as_completed: "وضع علامة كمكتمل",
  add_order: "أضف الطلب",
  cancel_order: "الغاء الطلب",
  order_added: "تمت إضافة الطلب",
  order_added_successfully: "تمت إضافة الطلب بنجاح!",
  order_updated: "تم تحديث الطلب",
  order_updated_successfully: "تم تحديث الطلب بنجاح!",

  // Tables
  table_add: "أضف طاولة",
  table_added: "تمت إضافة الطاولة",
  table_added_successfully: "تمت إضافة الطاولة بنجاح!",
  is_drive_thru: "الطلب من المركبة",
  drive_thru_add: "إضافة القيادة من خلال",
  drive_thru_added: "تمت إضافة خدمة القيادة من خلال.",
  drive_thru_added_successfully: "تمت إضافة القيادة من خلال بنجاح!",
  entity_updated: "تم تحديث الكيان",
  entity_updated_successfully: "تم تحديث الكيان بنجاح!",
  entity_deleted: "تم حذف الكيان",
  entity_deleted_successfully: "تم حذف الكيان بنجاح!",
  add_table: "إضافة جدول",
  update_table: "تحديث الجدول",
  assign_table_to_order: "تعيين الجدول للطلب",
  select_table: "حدد الجدول",
  validate_table_required: "حقل الجدول مطلوب.",

  // Employees
  employee_add: "اضافة موظف",
  employee_added: "تمت إضافة الموظف",
  employee_added_successfully: "تمت إضافة الموظف بنجاح!",
  employee_edit: "تحرير الموظف",
  employee_updated: "تم تحديث الموظف",
  employee_updated_successfully: "تم تحديث الموظف بنجاح!",
  employee_activate: "تفعيل موظف",
  employee_activated: "تم تنشيط الموظف",
  employee_activated_successfully: "تم تفعيل الموظف بنجاح!",
  employee_deactivate: "قم بإلغاء تنشيط الموظف",
  employee_deactivated: "الموظف معطل",
  employee_deactivated_successfully: "تم إلغاء تنشيط الموظف بنجاح!",
  employee_deleted: "تم حذف الموظف",
  employee_deleted_successfully: "تم حذف الموظف بنجاح!",

  // Helpdesk
  ticket_add: "أضف تذكرة",
  ticket_added: "تمت إضافة التذكرة",
  ticket_added_successfully: "تم إضافة التذكرة بنجاح!",
  ticket_updated: "تم تحديث التذكرة",
  ticket_updated_successfully: "تم تحديث التذكرة بنجاح!",

  // Discount
  discount_add: "أضف الخصم",
  discount_added: "تمت إضافة الخصم",
  discount_added_successfully: "تمت إضافة الخصم بنجاح!",
  discount_deleted: "تم حذف الخصم",
  discount_deleted_successfully: "تم حذف الخصم بنجاح!",
  discount_edit: "تحرير الخصم",
  discount_updated: "تم تحديث الخصم",
  discount_updated_successfully: "تم تحديث الخصم بنجاح!",

  // External Integration
  connection_add: "إضافة اتصال",
  connection_update: "تحديث الاتصال",
  connection_added: "تمت إضافة الاتصال",
  connection_added_successfully: "تمت إضافة الاتصال بنجاح!",
  connection_test: "اختبار الاتصال",
  connection_tested_successfully: "تم اختبار الاتصال بنجاح!",
  connection_tested_failed:
    "فشل اختبار الاتصال بسبب بيانات الاعتماد غير الصالحة!",
  connection_save: "حفظ الاتصال",
  connection_saved_successfully: "تم حفظ الاتصال بنجاح!",
  connection_saved_failed: "فشل حفظ الاتصال بسبب بيانات الاعتماد غير الصالحة!",
  connection_deleted: "تم حذف الاتصال",
  connection_deleted_successfully: "تم حذف الاتصال بنجاح!",

  // Subscriptions
  subscription_upgrade_requested: "طلبت ترقية الاشتراك",
  subscription_upgrade_requested_successfully: "تم طلب ترقية الاشتراك بنجاح!",

  // Addon
  addon_subscribed: "ملحق مشترك",
  addon_subscribed_successfully: "تم الاشتراك في الملحق بنجاح!",
  addon_unsubscribed: "تم إلغاء الاشتراك في الملحق",
  addon_unsubscribed_successfully: "تم إلغاء الاشتراك في الملحق بنجاح!",

  // invoices
  model: "موديل",
  imei: "IMEI",
  serial_no: "الرقم التسلسلي.",
  amount: "المبلغ",
  due_amount: "مبلغ مستحق",
  installments: "أقساط",
  warranty_from_to: "الضمان من - إلى",
  warranty_starting_at: "بداية الضمان",
  warranty_ending_at: "انتهاء الضمان",
  user_licenses_added_successfully: "تم إنشاء الفاتورة بنجاح!",
  user_licenses_have_been_added_successfully:
    "تمت إضافة تراخيص المستخدم بنجاح.",

  // topup
  wallet_details: "تفاصيل المحفظة",
  available_credit: "الرصيد المتاح",
  topup: "التعبئة",
  balance_validity: "صلاحية الرصيد",
  topup_request_received: "تم استلام طلب التعبئة",
  topup_request_received_successfully: "تم استلام طلب تعبئة الرصيد بنجاح!",
  confirmation: "تأكيد",
  check_confirmation_checkbox: "يرجى التحقق من خانة الاختيار للتأكيد!",
  enter_topup_amount: "ادخل مبلغ التعبئة",
  enter_reference_no: "أدخل رقم المرجع",
  minimum_topup_amount_required: "الحد الادنى المطلوب للتعبئة",
  your_balance_is_low_please_recharge_minimum:
    "رصيدك منخفض، يرجى إعادة شحن الحد الأدنى",

  // Waiter component
  waiter: "النادل",
  add_waiter: "إضافة النادل",
  is_waiter: "هو النادل",
  update_waiter: "تحديث النادل",
  waiter_updated: "تم تحديث النادل",
  assign_waiter_to_order: "تعيين النادل للطلب",
  select_waiter: "حدد النادل",
  validate_waiter_required: "حقل النادل مطلوب.",

  // job managment component
  job_person: "شخص الوظيفة",
  is_job_person: "هو شخص الوظيفة",
  select_job_person: "حدد شخص الوظيفة",
  job_person_updated: "تم تحديث شخص الوظيفة",
  update_job_person: "تحديث شخص الوظيفة",
  validate_job_person_required: "مطلوب الشخص الوظيفي.",

  // Inventory component
  adjustment_add: "إضافة التعديل",
  stock_adjustment_done: "تم تعديل المخزون",
  stock_adjustment_done_successfully: "لقد تم تعديل المخزون بنجاح!",
  adjustment_history: "تاريخ التعديل",
  direct_transfer: "تحويل مباشر",
  request_transfer: "طلب نقل",
  cancel_transfer: "إلغاء النقل",
  accept_transfer: "قبول النقل",
  reject_transfer: "رفض النقل",
  confirm_transfer: "تأكيد النقل",
  transfer_edit: "تحرير النقل",
  transfer_added: "تمت إضافة النقل",
  transfer_added_successfully: "تمت إضافة النقل بنجاح!",
  transfer_updated: "تم تحديث النقل",
  transfer_updated_successfully: "تم تحديث النقل بنجاح!",

  // Sales Invoice
  invoice_add: "أضف الفاتورة",
  invoice_added: "تمت إضافة الفاتورة",
  invoice_added_successfully: "تمت إضافة الفاتورة بنجاح!",
  save_as_draft: "حفظ كمسودة",
  issue_invoice: "إصدار الفاتورة",
  register_payment: "تسجيل الدفع",

  // Table Columns
  col_product: "المنتج",
  col_price: "سعر",
  col_category: "فئة",
  col_unit: "وحدة",
  col_barcode: "الرمز الشريطي",
  col_action: "إجراء",
  col_order_id: "معرف فريد",
  col_amount: "المبلغ",
  col_tax: "الضريبة",
  col_type: "الدفع",
  col_quantity: "الكمية",
  col_status: "الحالة",
  col_created_at: "أنشئت في",
  col_created_by: "انشأ من قبل",
  col_paid_at: "دفعت في",
  col_subtotal: "المجموع الفرعي",
  col_sold_quantities: "الكميات المباعة",
  col_gross_sales: "إجمالي المبيعات",
  col_refund_quantities: "الكميات الاسترداد",
  col_gross_refund: "إجمالي المبالغ المعادة",
  col_start_date: "تاريخ البدء",
  col_end_date: "تاريخ الانتهاء",
  col_date: "تاريخ",
  col_subscription_plan: "خطة الاشتراك",
  col_transaction_id: "رقم الفاتورة",
  col_discount_percentage: "نسبة الخصم",
  col_branches: "الفروع",
  invoice_ID: "مرجع الفاتورة",
  subscription: "الاشتراك",
  license: "رخصة",
  total_amount_charged: "المبلغ الإجمالي",
  col_items: "العناصر",
  col_user: "المستخدم",
  col_payment: "دفع",
  col_new: "تذاكر جديدة",
  col_inprogress: "التذاكر تحت الاجراء",
  col_done: "التذاكر المكتملة",
  col_closed: "التذاكر مغلقة",
  col_description: "وصف",
  col_attachment: "المرفق",
  col_allow_refund: "السماح برد الأموال",
  col_allow_add_products: "السماح بإضافة منتجات",
  col_allow_edit_products: "السماح بتحرير المنتجات",
  col_odoo_status: "حالة Odoo",
  col_source: "مصدر",
  col_pending_orders: "الأوامر المعلقة",
  col_inprogress_orders: "أوامر التقدم",
  col_completed_orders: "الطلبات المكتملة",
  col_cancelled_orders: "الطلبات الملغاة",
  col_branch_from: "فرع من",
  col_branch_to: "فرع ل",
  draft_invoices: "مشروع الفواتير",
  open_invoices: "فتح الفواتير",
  partially_paid_invoices: "الفواتير المدفوعة جزئيا",
  paid_invoices: "الفواتير المدفوعة",
  col_balance: "توازن",
  col_due_date: "تاريخ الاستحقاق",

  // Reports
  gross_sales: "إجمالي المبيعات",
  net_sales: "صافي المبيعات",
  total_sales: "إجمالي المبيعات",
  refunds: "المبالغ المستردة",
  taxes: "الضرائب",
  sales: "مبيعات",
  payments: "المدفوعات",
  net_total: "المجموع الصافي",
  total_refunds: "إجمالي المبالغ المستردة",
  total: "المجموع",
  vat: "ضريبة القيمة المضافة",
  total_vat: "إجمالي ضريبة القيمة المضافة",
  quarterly_vat: "ضريبة القيمة المضافة على المبيعات ربع السنوية",
  payment_types: "أنواع الدفع",
  quarterly_sales: "مبيعات ربع سنوية",
  sales_overview: "نظرة عامة على المبيعات",

  // Warnings
  warning_remove: "هل أنت متأكد من إزالة هذا الكيان؟",
  warning_delete: "هل أنت متأكد من حذف هذا الكيان؟",
  warning_logout: "هل أنت متأكد من تسجيل الخروج؟",
  warning_empty_basket: "هل أنت متأكد أنك تريد حذف هذه المنتجات من السلة؟",
  warning_remove_product: "هل أنت متأكد أنك تريد حذف هذا المنتج من السلة؟",
  warning_delete_stock_product:
    "يرجى التأكد إذا قمت بحذف هذا المنتج، فسيتم حذف المخزون تلقائيًا.",
  warning_unsubscribe_addon:
    "إذا قمت بإلغاء الاشتراك في الملحق، فسيتم إزالة البيانات من الخادم بعد 3 أيام",

  // Status
  status_paid: "المبلغ دفع",
  status_refunded: "المبلغ المردود",

  // Miscellaneous
  yes: "نعم",
  no: "لا",
  logout: "تسجيل الخروج",
  cancel: "إلغاء",
  add: "يضيف",
  edit: "يحرر",
  update: "تحديث",
  submit: "يُقدِّم",
  confirm: "يتأكد",
  search: "بحث",
  upload: "تحميل",
  back: "عودة",
  order_id: "معرف الطلب",
  order_details: "تفاصيل الطلب",
  issued_at: "تم إصداره في",
  date_range: "النطاق التواريخ",
  delete_entity: "حذف الكيان",
  something_went_wrong: "حدث خطأ ما",
  refund: "إعادة المبلغ",
  user_can_edit_price_on_pos: "يمكن للمستخدم تعديل السعر على نقاط البيع",
  user_can_apply_discount: "يمكن للمستخدم السماح بالخصم",
  user_see_transactions: "يمكن للمستخدم رؤية المعاملات",
  user_can_refund_transaction: "يمكن للمستخدم السماح باسترداد الفواتير",
  user_can_add_edit_products: "يمكن للمستخدم إضافة وتحرير المنتجات",
  user_can_request_stock_transfer: "يمكن للمستخدم السماح بطلب نقل المخزون",
  user_can_approve_stock_transfer:
    "يمكن للمستخدم السماح بالموافقة على نقل المخزون",
  user_allowed_stock_adjustment: "يُسمح للمستخدم بتعديل المخزون",
  user_can_add_edit_customers: "يمكن للمستخدم إضافة وتحرير العملاء",
  user_can_view_customers: "يمكن للمستخدم عرض العملاء",
  user_can_add_pay_sales_invoices: "يمكن للمستخدم إضافة ودفع فاتورة المبيعات",
  user_can_view_sales_invoices: "يمكن للمستخدم الاطلاع على فاتورة المبيعات",
  is_machine_user: "مستخدم الجهاز",
  proceed: "تقدم",
  checkout: "الدفع",
  payment_type: "نوع الدفع",
  cash_collected: "المبلغ المستلم",
  reference_no: "رقم المرجع",
  collected_by_cash: "تم تحصيله نقدًا",
  collected_by_card: "من البطاقة",
  charged_amount: "المبلغ الإجمالي",
  including_vat: "ضريبة القيمة المضافة",
  ask_more_cash: "اطلب المزيد من المال قبل المتابعة!",
  return_back_amount: "المتبقي",
  collect_more_cash: "جمع المزيد من المال",
  print: "طباعة",
  declaration: "تصريح",
  declaration_text:
    "أقر بموجبه أن المعلومات الواردة في هذا النموذج حقيقة وصحيحة وكاملة. وأؤكد مسؤليتي في حال العثور على أي معلومات غير صحيحة و / أو غير كاملة تؤدي إلى انتهاك اللوائح ، وقد تشرع في اتخاذ إجراءات قانونية ، وأوافق على أنني الطرف المسؤول عن ذلك وعن وجميع التهم والعقوبات والانتهاكات.",
  upgrade: "قم بالترقية",
  upgrade_subscription: "ترقية الاشتراك",
  payment_successful: "تم الدفع بنجاح!",
  your_payment_successfully: "تم الدفع الخاص بك بنجاح.",
  payment_failed: "عملية الدفع فشلت!",
  try_again_payment_failed:
    "لقد فشلت عملية الدفع الخاصة بك. من فضلك حاول مرة اخرى.",
  all: "الجميع",
  cart_empty: "عربة التسوق فارغة",
  total_amount: "المبلغ الإجمالي",
  licenses: "الترخيص (التراخيص)",
  purchase: "شراء",
  total_taxes: "إجمالي الضرائب",
  credit_card: "بطاقة ائتمان",
  stc_pay: "STC دفع",
  mada: "مدى",
  cash: "نقدي",
  card: "بطاقة",
  bank: "بنك",
  multi_payment: "متعدد الدفع",
  plz_fill_details: "يرجى ملء التفاصيل أدناه ، للمضي قدمًا",
  billing_details: "تفاصيل الفاتورة",
  active_subscription: "اشتراك نشط",
  available_upgrades: "الترقيات المتاحة",
  already_requested: "تم الطلب بالفعل",
  request_upgrade: "طلب ترقية",
  request_subscription_upgrade: "طلب ترقية الاشتراك",
  pro_plan: "الخطة احترافية",
  basic_plan: "الخطة الأساسية",
  discount: "تخفيض",
  select_a_discount: "حدد الخصم",
  total_amount_payable: "المبلغ الإجمالي المستحق",
  unpaid_invoice: "فاتورة غير مدفوعة!",
  pay_your_invoice: "يرجى دفع فاتورتك غير المسددة",
  show_invoices: "إظهار الفواتير",
  what_type_of_refund: "ما نوع الاسترداد الذي تريد القيام به؟",
  full: "كامل",
  partial: "جزئي",
  refund_amount: "المبلغ المسترد",
  qty: "الكمية",
  year: "سنة",
  month: "شهر",
  day: "يوم",
  additional: "إضافي",
  additional_info: "معلومات إضافية",
  users: "المستخدمون",
  pay_on: "ادفع على",
  action: "إجراء",
  pay_now: "ادفع الآن",
  view_invoice: "عرض الفاتورة",
  one_time_payment: "الدفع لمرة واحدة",
  months_plan: " خطة الأشهر",
  complete: "كامل",
  incomplete: "ناقص",
  max_upload_size_10mb_warning: "الحد الأقصى لحجم التحميل: 10 ميجابايت",
  max_upload_size_2mb_warning: "الحد الأقصى لحجم التحميل: 2 ميجابايت",
  account_under_verification: "حسابك قيد التحقق!",
  notified_once_verified:
    "شكرا لك على الاشتراك معنا، سوف تصلك رسالة نصية على هاتفك بعد التحقق من بيانات حسابك.",
  account_blocked: "حسابك محظور!",
  kindly_contact_at: "يرجى التواصل معنا على contact@anypos.app",
  billing_address_same_as_shop_address: "عنوان الفواتير هو نفسه عنوان المحل",
  is_vat_exempt: "لست خاضع لضريبة القيمة المضافة",
  vat_exempt_latter_sample: "مثال لإشعار طلب التسجيل( غير خاضع للضريبة)",
  vat_exempt_latter: "اشعار طلب التسجيل( غير خاضع للضريبة)",
  connection: "اتصال",
  connected: "متصل",
  disconnected: "انقطع الاتصال",
  send_to_odoo: "أرسل إلى Odoo",
  item_send_to_odoo_successfully: "تم إرسال العنصر إلى Odoo بنجاح!",
  download_report: "تحميل التقرير",
  download_sales_report: "تنزيل تقرير المبيعات",
  download_payments_report: "تحميل تقرير المدفوعات",
  download_template: "تحميل النموذج",
  skip: "تخطى",
  fill_the_details: "املأ التفاصيل",
  kindly_complete_the_store_details_within:
    "يرجى استكمال تفاصيل المتجر في الداخل",
  days_otherwise_your_account_will_be_blocked: "أيام وإلا سيتم حظر حسابك.",
  account_freezed: "تم تجميد حسابك",
  upgrade_subscription_unfreeze: "يرجى اختيار ترقية الاشتراك لالغاء التجميد",
  pay_generated_invoice_first: "ادفع الفاتورة المنشاة اولا",
  you_selected_following_package: "لقد اخترت الباقة التالية",
  unlimited: "غير محدود",
  per_user: "لكل مستخدم",
  payment_modal: "طريقة الدفع",
  select_payment_method: "اختر طريقة الدفع",
  service_name: "اسم الخدمة",
  subscriptions_history: "سجل الاشتراكات",
  spending_history: "تاريخ الإنفاق",
  trial_days: "الايام التجريبية",
  paid: "مدفوع",
  unpaid: "غير مدفوع",
  qr: "رمز الاستجابة السريعة",
  dining_table: "طاولة الطعام",
  note: "ملحوظة",
  clear: "واضح",
  selected_item: "العنصر المحدد",
  pending: "قيد الانتظار",
  completed: "مكتمل",
  cancelled: "ألغيت",
  rejected: "مرفوض",
  available_stock: "المخزون المتوفر",
  requested_stock: "الأسهم المطلوبة",
  current_stock: "المخزون الحالي",
  your_branch: "فرعك",
  approved_quantity: "الكمية المعتمدة",
  requested_quantity: "الكمية المطلوبة",
  insufficient_quantity: "كمية غير كافية",
  out_of_stock: "إنتهى من المخزن",
  product_already_selected: "المنتج محدد بالفعل",
  basket: "سلة",
  search_product_name_here: "ابحث عن اسم المنتج هنا",
  welcome_to: "اهلا بك في",
  call_to_waiter: "اتصل بالنادل",
  order: "طلب",
  select_language: "اختار اللغة",
  place_order: "مكان الامر",
  basket_empty: "سلتك فارغة!",
  menu: "قائمة طعام",
  order_placed_successfully: "تم تقديم طلبك بنجاح",
  hi: "مرحبا",
  welcome_anypos: "اهلا بك في AnyPOS!",
  place_new_order: "تقديم طلب جديد",
  your_order: "طلبك",
  expired: "منتهي الصلاحية",
  is_expired: "انتهت صلاحيته",
  late_payment: "التأخر في السداد",
  is_late_payment: "هو التأخر في السداد",
};

export default trans_ar_EG;
