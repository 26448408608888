import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Form,
  DatePicker,
  Input,
  Select,
  Button,
  Tag,
} from "antd";
import { getSaleInvoices } from "../../../network/network";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import { SALES_INVOICE_STATUS_DRAFT } from "../../../configs/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DraftInvoices = ({ branchesData }) => {
  const navigate = useNavigate();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  const [searchParams, setSearchParams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setIsDataLoading(true);
    getSaleInvoices({
      ...searchParams,
      page: currentPage,
      sale_invoice_status: SALES_INVOICE_STATUS_DRAFT,
    })
      .then((response) => {
        setTableData(response.data.data.saleInvoices);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [searchParams, currentPage]);

  // TableColumns Columns
  const TableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("col_created_at"),
      dataIndex: "created_at",
      render: (created_at) => {
        return <p>{moment(created_at).format("MMM. D, YYYY hh:mm a")}</p>;
      },
    },
    {
      title: t("customer_name"),
      render: (record) => {
        return <>{record.customer?.name_ar && record.customer?.name_ar}</>;
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return <p>{branch}</p>;
      },
    },
    {
      title: t("col_amount"),
      render: (record) => {
        return (
          <>
            <p>
              <CurrencyFormatter value={record.amount_charged} />
            </p>
          </>
        );
      },
    },
    {
      title: t("col_balance"),
      render: (record) => {
        return (
          <>
            <p>
              <CurrencyFormatter value={record.balance} />
            </p>
          </>
        );
      },
    },
    {
      title: t("col_due_date"),
      dataIndex: "invoice_due_date",
      render: (invoice_due_date) => {
        return (
          <div>
            {moment(invoice_due_date).diff(moment(), "seconds") < 0 && (
              <>
                <Tag color="error">{t("expired")}</Tag>
                <br />
              </>
            )}

            {moment(invoice_due_date).format("MMM. D, YYYY")}
          </div>
        );
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              navigate("/sales-invoice/" + record.id);
            }}
          >
            {t("preview")}
          </Button>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      let startDateUtc = moment.utc(values.dates[0]).set({
        date: values.dates[0].date() - 1,
        month: values.dates[0].month(),
        year: values.dates[0].year(),
        hour: 19,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let endDateUtc = moment.utc(values.dates[1]).set({
        date: values.dates[1].date(),
        month: values.dates[1].month(),
        year: values.dates[1].year(),
        hour: 18,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (values.filter_by_branch) {
      search_params = {
        filter_by_branch: values.filter_by_branch,
        ...search_params,
      };
    }
    if (values.is_expired === 0 || values.is_expired) {
      search_params = {
        is_expired: values.is_expired,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="filter_by_branch" label={t("branch")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="is_expired" label={t("is_expired")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={[
              {
                label: t("yes"),
                value: 1,
              },
              {
                label: t("no"),
                value: 0,
              },
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationData(pagination);
    setCurrentPage(pagination.current);
  };

  return (
    <>
      <Card>{searchForm}</Card>
      <Card>
        <Table
          columns={TableColumns}
          dataSource={tableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: paginationData.current_page,
            pageSize: paginationData.per_page,
            total: paginationData.total,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};

export default DraftInvoices;
