import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  Table,
  Tag,
  Form,
  DatePicker,
  Input,
  Select,
  Button,
} from "antd";
import { getOrder } from "../../../network/network";
import { AppContext } from "../../../context/AppContextProvider";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import {
  transactionTypes,
  USER_TYPE_BUSINESS_OWNER,
  transactionOrderSource,
  TRANSACTION_STATUS_INPROGRESS,
  ADDON_TABLE_MANAGEMENT,
} from "../../../configs/constants";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const InprogressOrders = ({ branchesData }) => {
  const navigate = useNavigate();
  const { authUser } = useContext(AppContext);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [ordersTableData, setOrdersTableData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  const [searchParams, setSearchParams] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    setIsDataLoading(true);
    getOrder({
      ...searchParams,
      page: currentPage,
      status: TRANSACTION_STATUS_INPROGRESS,
    })
      .then((response) => {
        setOrdersTableData(response.data.data.transactions);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [searchParams, currentPage]);

  // firstPartCol Columns
  const firstPartCol = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("order_id"),
      dataIndex: "order_id",
      render: (order_id) => {
        return order_id ? order_id : "-";
      },
    },
    {
      title: t("col_amount"),
      render: (record) => {
        return (
          <>
            {record.is_multipayment ? (
              record.multipayments.map((item, i) => {
                return (
                  <p key={i}>
                    {item.transaction_type}: {item.amount}
                  </p>
                );
              })
            ) : (
              <p>
                <CurrencyFormatter value={record.amount_charged} />
              </p>
            )}
          </>
        );
      },
    },
    {
      title: t("col_source"),
      render: (record) => {
        return (
          <>
            {record.order_source.name === "Pos" && (
              <img
                src="/icons/pos.svg"
                alt="pos code"
                style={{ width: "20px", height: "auto", marginRight: "10px" }}
              />
            )}
            {record.order_source.name === "QrOrder" &&
              (record.dining_table.is_drive_thru ? (
                <img
                  src="/icons/car.svg"
                  alt="qr code"
                  style={{
                    width: "20px",
                    height: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <img
                  src="/icons/qr.svg"
                  alt="qr code"
                  style={{ width: "20px", height: "auto", marginRight: "10px" }}
                />
              ))}
            {record.order_source.name === "Kiosk" && (
              <img
                src="/icons/kiosk.svg"
                alt="kiosk code"
                style={{ width: "20px", height: "auto", marginRight: "10px" }}
              />
            )}
            {record.dining_table && is_table_management_active && (
              <Tag color="blue">{record.dining_table.name}</Tag>
            )}
          </>
        );
      },
    },
  ];
  // Branch Columns
  const branchCol = [
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (branch) => {
        return <p>{branch}</p>;
      },
    },
  ];
  // secondPartCol Columns
  const secondPartCol = [
    {
      title: `${t("col_created_at")} / ${t("col_created_by")}`,
      render: (record) => {
        return (
          <>
            <p>{moment(record.created_at).format("MMM. D, YYYY hh:mm a")}</p>
            {record.user && <Tag color="cyan">{record.user}</Tag>}
          </>
        );
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              navigate("/orders/" + record.id);
            }}
          >
            {t("preview")}
          </Button>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.dates) {
      let startDateUtc = moment.utc(values.dates[0]).set({
        date: values.dates[0].date() - 1,
        month: values.dates[0].month(),
        year: values.dates[0].year(),
        hour: 19,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      let endDateUtc = moment.utc(values.dates[1]).set({
        date: values.dates[1].date(),
        month: values.dates[1].month(),
        year: values.dates[1].year(),
        hour: 18,
        minute: 59,
        second: 59,
        millisecond: 0,
      });

      search_params = {
        search_start_date: startDateUtc.format(),
        search_end_date: endDateUtc.format(),
      };
    }
    if (values.type) {
      search_params = {
        type: values.type,
        ...search_params,
      };
    }
    if (values.order_source) {
      search_params = {
        order_source: values.order_source,
        ...search_params,
      };
    }
    if (values.filter_by_branch) {
      search_params = {
        filter_by_branch: values.filter_by_branch,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="dates" label={t("date_range")}>
          <RangePicker allowClear format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item name="filter_by_branch" label={t("branch")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={branchesData.map((branch) => {
              return {
                label: branch.name,
                value: branch.name,
              };
            })}
          />
        </Form.Item>
        <Form.Item name="type" label={t("col_type")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={transactionTypes.map((type) => {
              return {
                label: type.text,
                value: type.id,
              };
            })}
          />
        </Form.Item>

        <Form.Item name="order_source" label={t("col_source")}>
          <Select
            allowClear
            style={{ width: "100px" }}
            options={transactionOrderSource.map((type) => {
              return {
                label: type.text,
                value: type.id,
              };
            })}
          />
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTransactionsTableChange = (pagination, filters, sorter) => {
    setPaginationData(pagination);
    setCurrentPage(pagination.current);
  };

  const is_table_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_TABLE_MANAGEMENT
  );

  return (
    <>
      <Card>{searchForm}</Card>
      <Card>
        <Table
          columns={
            authUser.type === USER_TYPE_BUSINESS_OWNER
              ? [...firstPartCol, ...branchCol, ...secondPartCol]
              : [...firstPartCol, ...secondPartCol]
          }
          dataSource={ordersTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: paginationData.current_page,
            pageSize: paginationData.per_page,
            total: paginationData.total,
            showSizeChanger: false,
          }}
          onChange={handleTransactionsTableChange}
        />
      </Card>
    </>
  );
};

export default InprogressOrders;
