import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Tag,
  Button,
  Form,
  Select,
  Input,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  DeploymentUnitOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { showAddons } from "../../../network/network";
import { useTranslation } from "react-i18next";
import CurrencyFormatter from "../../../components/CurrencyFormatter";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useNavigate } from "react-router-dom";

import AddonAction from "./AddonAction";

const { Text } = Typography;

const Addons = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [addonData, setAddonData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    setIsDataLoading(true);
    showAddons(searchParams)
      .then((response) => {
        setAddonData(response.data.data.addons);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadData, searchParams]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "addons",
      breadcrumbName: t("menu_addons"),
      icon: <DeploymentUnitOutlined />,
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;
    if (values.status) {
      search_params = {
        status: values.status,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="status" label={t("col_status")}>
          <Select
            allowClear
            style={{ width: "150px" }}
            options={[
              {
                value: "all",
                label: t("all"),
              },
              {
                value: "subscribe",
                label: t("subscribe"),
              },
              {
                value: "unsubscribe",
                label: t("unsubscribe"),
              },
            ]}
          />
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_addons")}
          ghost={false}
          style={{ padding: "0" }}
          footer={searchForm}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row gutter={20}>
          {addonData.map((addon) => {
            return (
              <Col
                className="gutter-row"
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={6}
                key={addon.id}
              >
                <div
                  style={{ position: "absolute", zIndex: "99", margin: "10px" }}
                >
                  {addon.is_subscribed && (
                    <Tag color="green">{t("addon_subscribed")}</Tag>
                  )}
                </div>
                <Card
                  style={{ marginBottom: 20 }}
                  cover={
                    <img
                      alt={addon.name}
                      src={
                        addon.image
                          ? addon.image
                          : "/images/image-not-found.png"
                      }
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "fill",
                      }}
                    />
                  }
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => {
                        navigate("/addons/" + addon.id);
                      }}
                      size="small"
                    >
                      <EyeOutlined /> {t("preview")}
                    </Button>,
                    <AddonAction
                      addonData={addon}
                      reloadCallback={() => setReloadData(!reloadData)}
                      buttonSize="small"
                    />,
                  ]}
                >
                  <div>
                    <h3
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {addon.name}
                    </h3>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {addon.discount > 0 ? (
                        <>
                          <small>
                            <Text delete type="danger">
                              <CurrencyFormatter value={addon.price} />
                            </Text>
                          </small>
                          <span
                            style={{
                              color: "#8629ff",
                            }}
                          >
                            <CurrencyFormatter
                              value={addon.price - addon.discount}
                            />
                          </span>
                        </>
                      ) : addon.trial_validity_days > 0 ? (
                        addon.price !== "0.00" ? (
                          <>
                            <small>
                              <Text delete type="danger">
                                <CurrencyFormatter value={addon.price} />
                              </Text>
                            </small>{" "}
                            <span
                              style={{
                                color: "#8629ff",
                              }}
                            >
                              <CurrencyFormatter value={0} />
                            </span>
                          </>
                        ) : (
                          <span
                            style={{
                              color: "#8629ff",
                            }}
                          >
                            <CurrencyFormatter value={0} />
                          </span>
                        )
                      ) : (
                        <span
                          style={{
                            color: "#8629ff",
                          }}
                        >
                          <CurrencyFormatter value={addon.price} />
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("trial_days")} : {addon.trial_validity_days}
                    </div>
                  </div>
                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    {addon.description.length > 120
                      ? addon.description.substring(0, 120) + "..."
                      : addon.description}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Spin>
    </>
  );
};

export default Addons;
