import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Select,
  Radio,
  Row,
  Col,
  Space,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  DeleteTwoTone,
  EditTwoTone,
  ApartmentOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import {
  getCustomers,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getCities,
  getRegions,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContextProvider";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import {
  ADDON_CUSTOMERS_MANAGEMENT,
  USER_TYPE_BUSINESS_OWNER,
} from "../../../configs/constants";

const Customers = () => {
  const { authUser, appConfig } = useContext(AppContext);
  const ADD_CUSTOMER = "Add Customer";
  const [loadingBTN, setLoadingBTN] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modalTitle, setModalTitle] = useState(ADD_CUSTOMER);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [userType, setUserType] = useState([]);
  const [searchParams, setSearchParams] = useState([]);
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [customerForm] = Form.useForm();
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const is_customer_management_active = authUser.active_addons.some(
    (addon) => addon.id && addon.name === ADDON_CUSTOMERS_MANAGEMENT
  );

  useEffect(() => {
    setIsDataLoading(true);
    getCustomers({
      ...searchParams,
      page: currentPage,
      per_page: 15,
    })
      .then((response) => {
        setTableData(response.data.data.customers);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData, searchParams, currentPage]);

  useEffect(() => {
    getRegions().then((response) => {
      setRegions(response.data.data.regions);
    });
    setUserType([]);
  }, []);

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Set Modal Title
    setModalTitle(t("customer_add"));
    // Reset Form Fields
    customerForm.resetFields();
    customerForm.setFieldsValue({
      user_type: "individual",
      country: "Saudi Arabia",
    });
    setUserType("individual");
  };

  const handleAPIResponse = (response, type) => {
    if (response.data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_CUSTOMER) {
        NotificationWithIcon(
          "success",
          t("customer_added"),
          t("customer_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("customer_updated"),
          t("customer_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon(
        "error",
        t("something_went_wrong"),
        response.data.message
      );
    }
    // Reset Form Fields
    customerForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    customerForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoadingBTN(true);
        // Sending Request to API
        if (modalTitle === ADD_CUSTOMER || modalTitle === t("customer_add")) {
          createCustomer(values)
            .then((response) => handleAPIResponse(response, ADD_CUSTOMER))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoadingBTN(false);
            });
        } else {
          updateCustomer(values)
            .then((response) => handleAPIResponse(response, null))
            .catch((info) => {
              console.log("API Failed:", info.response);
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                info.response.data.data.errors.name
              );
            })
            .finally(() => {
              // Set Loading False
              setLoadingBTN(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCityRequest = (value) => {
    if (value) {
      getCities(value).then((response) => {
        setCities(response.data.data.cities);
      });
    }
  };

  const handleEditModal = (customer) => {
    customerForm.resetFields();
    // Set Modal Title
    setModalTitle(t("customer_edit"));

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    customerForm.setFieldsValue({
      id: customer.id,
      name_ar: customer.name_ar,
      name_en: customer.name_en,
      phone: customer.phone,
      user_type: customer.user_type,
      vat: customer.vat,
      cr: customer.cr,
      city_id: customer.city?.id,
      state_id: customer.state?.id,
      country: customer.country || "Saudi Arabia",
      postal_code: customer.postal_code,
      street: customer.street,
      building_number: customer.building_number,
      plot_id_number: customer.plot_id_number,
    });
    setUserType(customer.user_type);
    handleCityRequest(customer.state?.id);
  };

  const handleCancel = () => {
    // Reset Form Fields
    customerForm.resetFields();
    setModalVisible(false);
  };

  const handleDelete = (id) => {
    deleteCustomer(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("customer_deleted"),
            t("customer_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "customers",
      breadcrumbName: t("menu_customers"),
      icon: <ApartmentOutlined />,
    },
  ];

  const TableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return paginationData.current_page
          ? paginationData.per_page * (paginationData.current_page - 1) +
              (index + 1)
          : index + 1;
      },
    },
    {
      title: t("name_ar"),
      render: (record) => {
        return <>{record.name_ar}</>;
      },
    },
    {
      title: t("name_en"),
      render: (record) => {
        return <>{record.name_en ? record.name_en : "-"}</>;
      },
    },
    {
      title: t("phone_number"),
      render: (record) => {
        return <>{record.phone ? record.phone : "-"}</>;
      },
    },
    {
      title: t("col_type"),
      render: (record) => {
        return <>{record.user_type ? record.user_type : "-"}</>;
      },
    },
    {
      title: t("col_action"),
      align: "center",
      width: "100px",
      render: (record) => {
        return (
          <>
            <Space
              style={{
                display: "flex",
                justifyContent: "center",
                columnGap: "20px",
                padding: "5px 0",
              }}
            >
              {is_customer_management_active &&
                (authUser.type === USER_TYPE_BUSINESS_OWNER ||
                  authUser.can_add_edit_customer) && (
                  <Button
                    type="link"
                    key="edit"
                    onClick={() => {
                      handleEditModal(record);
                    }}
                    title={t("customer_edit")}
                    style={{ padding: "0" }}
                  >
                    <EditTwoTone twoToneColor="#0000ff" />
                  </Button>
                )}
              <Button
                type="link"
                key="View"
                title="View Details"
                onClick={() => {
                  navigate("/customers/" + record.id);
                }}
                style={{ padding: "0" }}
              >
                <EyeTwoTone twoToneColor="#0000ff" />
              </Button>
              {is_customer_management_active &&
                (authUser.type === USER_TYPE_BUSINESS_OWNER ||
                  authUser.can_add_edit_customer) && (
                  <Popconfirm
                    title={t("warning_delete")}
                    onConfirm={() => handleDelete(record.id)}
                    okText={t("yes")}
                    cancelText={t("no")}
                    key="delete"
                  >
                    <DeleteTwoTone
                      twoToneColor="#ff0000"
                      title={t("delete_entity")}
                    />
                  </Popconfirm>
                )}
            </Space>
          </>
        );
      },
    },
  ];

  const handleRegionSelect = (value) => {
    customerForm.resetFields(["city_id"]);
    getCities(value).then((response) => {
      setCities(response.data.data.cities);
    });
  };

  const handleRegionSelectforFilter = (value) => {
    searchFormRef.resetFields(["city_id"]);
    getCities(value).then((response) => {
      setCities(response.data.data.cities);
    });
  };

  const handleSearch = (values) => {
    let search_params = false;

    if (values.user_type) {
      search_params = {
        user_type: values.user_type,
      };
    }
    if (values.phone) {
      search_params = {
        phone: values.phone,
        ...search_params,
      };
    }
    if (values.state_id) {
      search_params = {
        state_id: values.state_id,
        ...search_params,
      };
    }
    if (values.city_id) {
      search_params = {
        city_id: values.city_id,
        ...search_params,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // Reset Transactions List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="user_type" label={t("type")}>
          <Select
            allowClear
            style={{ width: "120px" }}
            options={[
              {
                value: "individual",
                label: t("individual"),
              },
              {
                value: "company",
                label: t("company"),
              },
            ]}
          />
        </Form.Item>
        <Form.Item name="phone" label={t("phone_number")}>
          <Input style={{ width: "150px" }} />
        </Form.Item>
        <Form.Item name="state_id" label={t("state")}>
          <Select
            allowClear
            style={{ width: "150px" }}
            showSearch
            onChange={handleRegionSelectforFilter}
            options={regions.map((region) => {
              return {
                label:
                  appConfig.direction === "ltr"
                    ? region.name_en
                    : region.name_ar,
                value: region.id,
              };
            })}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="city_id" label={t("city")}>
          <Select
            allowClear
            style={{ width: "150px" }}
            showSearch
            options={cities.map((city) => {
              return {
                label:
                  appConfig.direction === "ltr" ? city.name_en : city.name_ar,
                value: city.id,
              };
            })}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationData(pagination);
    setCurrentPage(pagination.current);
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb menus={breadcrumbRoutes} />
        <PageHeader
          title={t("menu_customers")}
          ghost={false}
          style={{ padding: "0" }}
          footer={searchForm}
          extra={
            is_customer_management_active &&
            (authUser.type === USER_TYPE_BUSINESS_OWNER ||
              authUser.can_add_edit_customer) && (
              <Button
                key="open-customer-modal"
                type="primary"
                onClick={showModal}
              >
                + {t("customer_add")}
              </Button>
            )
          }
        />
      </Card>
      <Card>
        <Table
          columns={TableColumns}
          dataSource={tableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: paginationData.current_page,
            pageSize: paginationData.per_page,
            total: paginationData.total,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" loading={loadingBTN} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loadingBTN}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_CUSTOMER || modalTitle === t("customer_add")
              ? t("customer_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="customer-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={customerForm}
        >
          <Row gutter={[20, 10]}>
            <Col span={12}>
              <Form.Item
                name="name_ar"
                label={t("name_ar")}
                rules={[
                  {
                    required: true,
                    message: t("validate_name_required"),
                    whitespace: true,
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name_en" label={t("name_en")} hasFeedback>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t("phone_number") + " eg: +966 5xxxxxxx"}
                rules={[
                  {
                    min: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                  {
                    max: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                ]}
                style={{ textAlign: "left" }}
                hasFeedback
              >
                <PhoneInput
                  localization={["sa"]}
                  masks={{ sa: "(...) ..-..-.." }}
                  placeholder="+966 (123) 45-67-89"
                  country={"sa"}
                  onlyCountries={["sa"]}
                  inputStyle={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="user_type"
                label={t("type")}
                rules={[
                  {
                    required: true,
                    message: t("validate_type_required"),
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setUserType(e.target.value);
                  }}
                >
                  <Radio value="individual">{t("individual")}</Radio>
                  <Radio value="company">{t("company")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {userType === "company" && (
              <>
                <Col span={8}>
                  <Form.Item
                    name="vat"
                    label={t("vat_number")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_vat_number_required"),
                      },
                      {
                        min: 15,
                        message: t("validate_vat_number_exact_limit"),
                      },
                      {
                        max: 15,
                        message: t("validate_vat_number_exact_limit"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input type="number" maxLength={15} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="cr"
                    label={t("cr_number")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_cr_number_required"),
                      },
                      {
                        min: 10,
                        message: t("validate_cr_number_exact_limit"),
                      },
                      {
                        max: 10,
                        message: t("validate_cr_number_exact_limit"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input type="number" maxLength={10} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="country"
                    label={t("country")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_country_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="state_id"
                    label={t("state")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_state_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      showSearch
                      onChange={handleRegionSelect}
                      options={regions.map((region) => {
                        return {
                          label:
                            appConfig.direction === "ltr"
                              ? region.name_en
                              : region.name_ar,
                          value: region.id,
                        };
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="city_id"
                    label={t("city")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_city_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      showSearch
                      options={cities.map((city) => {
                        return {
                          label:
                            appConfig.direction === "ltr"
                              ? city.name_en
                              : city.name_ar,
                          value: city.id,
                        };
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="postal_code"
                    label={t("postal_code")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_post_code_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input type="number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="street"
                    label={t("street")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_street_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="building_number"
                    label={t("building_number")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_building_number_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="plot_id_number"
                    label={t("plot_id_number")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_plot_id_number_required"),
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
          <Form.Item name="id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Customers;
