import { doGet, doPost, doDelete, doPut, doDownload } from "./config";

// -------------------- Auth API's-----------------------------------
export const userLogin = (data) => {
  return doPost("/login", data, "");
};

export const userLogout = (data) => {
  return doPost("/logout", data, localStorage.getItem("access_token"));
};

export const userRegister = (data) => {
  return doPost("/register", data, "");
};

export const userRegisterValidate = (data) => {
  return doPost("/register/validate", data, "");
};

export const getMyProfile = () => {
  return doGet("/is-login", localStorage.getItem("access_token"));
};

export const updateAuthDetails = (data) => {
  return doPost(
    `/users/update`,
    data,
    localStorage.getItem("access_token"),
    true
  );
};

// export const getEmailFromToken = (resetToken) => {
//   return doGet(`/reset/${resetToken}`, "");
// };

// export const updatePassword = (data) => {
//   return doPatch("/resetPassword", data, "");
// };

export const sendOTPSMS = (data) => {
  return doPost(`/send-otp-sms`, data, "");
};

// Forgot

export const verifyForgotOtp = (data) => {
  return doPost("/verify/pin", data, "");
};

export const forgotPassword = (data) => {
  return doPost("/forgot-password", data, "");
};

export const resetPassword = (data) => {
  return doPost("/reset-password", data, "");
};

// =====================Auth API's end===============================

// -------------------- Home API's ----------------------------------
export const getHomeDataSummary = (data) => {
  return doGet(
    "/reports/home-data-summary",
    localStorage.getItem("access_token"),
    {}
  );
};
// ==================== Home API's end ==============================

// -------------------- Products API's-------------------------------
export const createProduct = (formData) => {
  return doPost(
    "/products",
    formData,
    localStorage.getItem("access_token"),
    true
  );
};
export const updateProduct = (formData) => {
  return doPost(
    "/products/" + formData.get("id"),
    formData,
    localStorage.getItem("access_token"),
    true
  );
};
export const getProducts = (data) => {
  return doGet("/products", localStorage.getItem("access_token"), data);
};
export const deleteProduct = (id) => {
  return doDelete("/products/" + id, {}, localStorage.getItem("access_token"));
};
export const sendProductToOdoo = (id) => {
  return doPost(
    "/products/" + id + "/odoo/send",
    {},
    localStorage.getItem("access_token")
  );
};
export const getProductTemplateExport = () => {
  return doDownload(
    "/products/template/excel/export",
    localStorage.getItem("access_token"),
    "Product_Template.xlsx"
  );
};
export const importProduct = (data) => {
  return doPost(
    "/products/excel/import",
    data,
    localStorage.getItem("access_token")
  );
};
// =====================Products API's end===========================

// -------------------- Products Categories API's-------------------------------
export const createProductCategory = (data) => {
  return doPost(
    "/product-categories",
    data,
    localStorage.getItem("access_token")
  );
};
export const updateProductCategory = (data) => {
  return doPut(
    "/product-categories/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
export const getProductCategories = (data) => {
  return doGet(
    "/product-categories",
    localStorage.getItem("access_token"),
    data
  );
};
export const deleteProductCategory = (id) => {
  return doDelete(
    "/product-categories/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
export const sendCategoryToOdoo = (id) => {
  return doPost(
    "/product-categories/" + id + "/odoo/send",
    {},
    localStorage.getItem("access_token")
  );
};
export const getCategoryTemplateExport = () => {
  return doDownload(
    "/product-categories/template/excel/export",
    localStorage.getItem("access_token"),
    "Category_Template.xlsx"
  );
};
export const importProductCategory = (data) => {
  return doPost(
    "/product-categories/excel/import",
    data,
    localStorage.getItem("access_token")
  );
};
// =====================Products Categories API's end===========================

// -------------------- Products Units API's-------------------------------
export const createProductUnit = (data) => {
  return doPost("/product-units", data, localStorage.getItem("access_token"));
};
export const updateProductUnit = (data) => {
  return doPut(
    "/product-units/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
export const getProductUnits = (data) => {
  return doGet("/product-units", localStorage.getItem("access_token"), data);
};
export const deleteProductUnit = (id) => {
  return doDelete(
    "/product-units/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
export const sendUnitToOdoo = (id) => {
  return doPost(
    "/product-units/" + id + "/odoo/send",
    {},
    localStorage.getItem("access_token")
  );
};
export const getUnitsTemplateExport = () => {
  return doDownload(
    "/product-units/template/excel/export",
    localStorage.getItem("access_token"),
    "Unit_Template.xlsx"
  );
};
export const importProductUnit = (data) => {
  return doPost(
    "/product-units/excel/import",
    data,
    localStorage.getItem("access_token")
  );
};

// =====================Products Units API's end===========================

// --------------------- Transactions API's -------------------------------
export const getTransactions = (data) => {
  return doGet("/transactions", localStorage.getItem("access_token"), data);
};
export const getTransactionQRCode = (transaction_id) => {
  return doGet(
    "/transactions/" + transaction_id + "/qrcode",
    localStorage.getItem("access_token")
  );
};
export const createTransactions = (data) => {
  return doPost("/transactions", data, localStorage.getItem("access_token"));
};
export const refundTransaction = (data) => {
  return doPost(
    "/transactions/" + data.transaction_id + "/refund/" + data.refund_type,
    data,
    localStorage.getItem("access_token")
  );
};
export const sendTransactionToOdoo = (id) => {
  return doPost(
    "/transactions/" + id + "/odoo/send",
    {},
    localStorage.getItem("access_token")
  );
};
export const downloadExcelReport = () => {
  return doDownload(
    "/transactions/excel/export",
    localStorage.getItem("access_token"),
    "Transactions.xlsx"
  );
};
export const getTransactionInvoice = (transaction_id) => {
  return doGet("/transactions/" + transaction_id + "/invoice");
};
export const getOrder = (data) => {
  return doGet("/orders", localStorage.getItem("access_token"), data);
};
export const getOrdersCount = () => {
  return doGet("/orders/counts", localStorage.getItem("access_token"));
};
export const getSingleTransaction = (transaction_id) => {
  return doGet(
    "/transactions/" + transaction_id,
    localStorage.getItem("access_token")
  );
};
export const editTransaction = (data) => {
  return doPost(
    "/transactions/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const ChangeTransactionStatus = (data) => {
  return doPost(
    "/transactions/" + data.get("id") + "/status/change",
    data,
    localStorage.getItem("access_token")
  );
};
export const ChangeTransactionWaiter = (data) => {
  return doPost(
    "/transactions/" + data.id + "/waiter/change",
    data,
    localStorage.getItem("access_token")
  );
};
export const ChangeTransactionTable = (data) => {
  return doPost(
    "/transactions/" + data.id + "/table/change",
    data,
    localStorage.getItem("access_token")
  );
};

// ===================== Transactions API's end ===========================

// --------------------- Subscriptions API's -------------------------------
export const getSubscriptions = (data) => {
  return doGet("/subscriptions", localStorage.getItem("access_token"), data);
};
export const createSubscription = (data) => {
  return doPost("/subscriptions", data, localStorage.getItem("access_token"));
};
// ===================== Subscriptions API's end ===========================

// --------------------- Branches API's ------------------------------------

export const getBranches = (data) => {
  return doGet("/branches", localStorage.getItem("access_token"), data);
};
export const createBranch = (data) => {
  return doPost("/branches", data, localStorage.getItem("access_token"));
};
export const updateBranch = (data) => {
  return doPut(
    "/branches/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteBranch = (id) => {
  return doDelete("/branches/" + id, {}, localStorage.getItem("access_token"));
};
export const sendBranchToOdoo = (id) => {
  return doPost(
    "/branches/" + id + "/odoo/send",
    {},
    localStorage.getItem("access_token")
  );
};

// ===================== Branches API's end ================================

// --------------------- Employees API's -------------------------------
export const createEmployee = (data) => {
  return doPost("/employees", data, localStorage.getItem("access_token"));
};
export const getEmployees = (data) => {
  return doGet("/employees", localStorage.getItem("access_token"), data);
};
export const updateEmployee = (data) => {
  return doPost(
    "/employees/" + data.id,
    data,
    localStorage.getItem("access_token")
  );
};
export const activateEmployee = (employee_id) => {
  return doPost(
    "/employees/" + employee_id + "/activate",
    {},
    localStorage.getItem("access_token")
  );
};
export const deactivateEmployee = (employee_id) => {
  return doPost(
    "/employees/" + employee_id + "/deactivate",
    {},
    localStorage.getItem("access_token")
  );
};
export const deleteEmployee = (employee_id) => {
  return doDelete(
    "/employees/" + employee_id,
    {},
    localStorage.getItem("access_token")
  );
};
export const machineUserToggle = (employee_id) => {
  return doPost(
    "/employees/" + employee_id + "/machine-user/toggle",
    {},
    localStorage.getItem("access_token")
  );
};
export const sendEmployeeToOdoo = (id) => {
  return doPost(
    "/employees/" + id + "/odoo/send",
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== Employees API's end ===========================

// --------------------- Reports API's ------------------------------------
export const getSalesSummary = (data) => {
  return doGet(
    "/reports/sales-summary",
    localStorage.getItem("access_token"),
    data
  );
};
export const getSalesByItems = (data) => {
  return doGet(
    "/reports/sales-by-items",
    localStorage.getItem("access_token"),
    data
  );
};
export const getSalesByCategories = (data) => {
  return doGet(
    "/reports/sales-by-categories",
    localStorage.getItem("access_token"),
    data
  );
};
export const getRefundsByItems = (data) => {
  return doGet(
    "/reports/refunds-by-items",
    localStorage.getItem("access_token"),
    data
  );
};
export const getRefundsByCategories = (data) => {
  return doGet(
    "/reports/refunds-by-categories",
    localStorage.getItem("access_token"),
    data
  );
};
export const getReportByBranches = (data) => {
  return doGet(
    "/reports/sales-by-branches",
    localStorage.getItem("access_token"),
    data
  );
};
// ===================== Reports API's end ================================

// --------------------- Subscription Plan API's --------------------------
export const getSubscriptionPlansAsObject = (data) => {
  return doGet("/subscription-plans", "", {});
};
// ===================== Subscription Plan API's end ======================

// --------------------- Payment API's ------------------------------------
export const prepareCheckout = (data) => {
  return doPost(
    "/payments/prepare-checkout",
    data,
    localStorage.getItem("access_token")
  );
};
export const topupBalance = (data) => {
  return doPost(
    "/payments/balance/topup",
    data,
    localStorage.getItem("access_token")
  );
};
export const verifyPayment = (data) => {
  return doPost("/payments/verify", data, localStorage.getItem("access_token"));
};
export const generateLicenseInvoice = (data) => {
  return doPost(
    "/invoices/generate-license-invoice",
    data,
    localStorage.getItem("access_token")
  );
};
// ===================== Payment API's end ================================

// --------------------- invoices API's ------------------------------------
export const getinvoices = () => {
  return doGet("/invoices", localStorage.getItem("access_token"), {});
};
// ===================== invoices API's end ================================

// --------------------- devices API's ------------------------------------
export const getdevices = () => {
  return doGet("/devices", localStorage.getItem("access_token"), {});
};
// ===================== devices API's end ================================

// --------------------- Regions API's -------------------------------
export const getRegions = (data) => {
  return doGet("/regions", localStorage.getItem("access_token"), data);
};
// ===================== Regions API's end ===========================
// --------------------- Cities API's -------------------------------
export const getCities = (region_id, data) => {
  return doGet(
    "/regions/" + region_id + "/cities",
    localStorage.getItem("access_token"),
    data
  );
};
// ===================== Cities API's end ===========================

// --------------------- Helpdesk API's ------------------------------------

export const createTicket = (data) => {
  return doPost(
    "/helpdesk/tickets",
    data,
    localStorage.getItem("access_token"),
    true
  );
};
export const getTicketsCount = (data) => {
  return doGet("/helpdesk/tickets", localStorage.getItem("access_token"), data);
};
export const getNewTickets = (page) => {
  return doGet(
    "/helpdesk/tickets/new?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getInprogressTickets = (page) => {
  return doGet(
    "/helpdesk/tickets/inprogress?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getDoneTickets = (page) => {
  return doGet(
    "/helpdesk/tickets/done?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
export const getClosedTickets = (page) => {
  return doGet(
    "/helpdesk/tickets/closed?page=" + page,
    localStorage.getItem("access_token"),
    {}
  );
};
// ===================== Helpdesk API's end ===========================

// --------------------- Discounts API's ------------------------------------

export const getAllDiscounts = (data) => {
  return doGet("/discounts", localStorage.getItem("access_token"), data);
};
export const getBranchDiscounts = (data) => {
  return doGet(
    "/discounts/branches/index",
    localStorage.getItem("access_token"),
    data
  );
};
export const createDiscount = (data) => {
  return doPost("/discounts", data, localStorage.getItem("access_token"));
};
export const updateDiscount = (data) => {
  return doPost(
    "/discounts/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteDiscount = (id) => {
  return doDelete("/discounts/" + id, {}, localStorage.getItem("access_token"));
};

// ===================== Discounts API's end ================================

// --------------------- External Integration API's ------------------------------------
export const getAllExternalIntegrationTypes = () => {
  return doGet(
    "/external/integrations/types",
    localStorage.getItem("access_token"),
    {}
  );
};
export const getAllExternalIntegration = () => {
  return doGet(
    "/external/integrations",
    localStorage.getItem("access_token"),
    {}
  );
};
export const creatExternalIntegration = (data) => {
  return doPost(
    "/external/integrations",
    data,
    localStorage.getItem("access_token")
  );
};
export const testExternalIntegration = (data) => {
  return doPost(
    "/external/integrations/connection",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteExternalIntegration = (id) => {
  return doDelete(
    "/external/integrations/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
// ===================== External Integration API's end ================================

// ---------------------  Addon API's -------------------------------

export const showAddons = (data) => {
  return doGet("/addons", localStorage.getItem("access_token"), data);
};
export const subscribeAddon = (id) => {
  return doPost(
    "/companies/addons/" + id,
    {},
    localStorage.getItem("access_token")
  );
};
export const unSubscribeAddon = (id) => {
  return doPost(
    "/companies/addons/" + id + "/unsubscribe",
    {},
    localStorage.getItem("access_token")
  );
};

// =====================  Addon API's end ===========================

// ---------------------  Dining Tables API's -------------------------------

export const getDiningTables = (data) => {
  return doGet("/diningTables", localStorage.getItem("access_token"), data);
};
export const createDiningTables = (data) => {
  return doPost("/diningTables", data, localStorage.getItem("access_token"));
};
export const updateDiningTables = (data) => {
  return doPost(
    "/diningTables/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteDiningTables = (id) => {
  return doPost(
    "/diningTables/" + id + "/delete",
    {},
    localStorage.getItem("access_token")
  );
};

// =====================  Dining Tables API's end ===========================

// ---------------------  QR products API's -------------------------------
export const getQRproducts = (data) => {
  return doGet("/qr/products/", {}, data);
};
export const placeQRproductsOrder = (data) => {
  return doPost(
    "/qr/orders?src=" + data.src + "&table=" + data.table,
    data,
    {}
  );
};
// =====================  QR products API's end ===========================

// ---------------------  Inventory API's -------------------------------
export const getStock = () => {
  return doGet("/stock", localStorage.getItem("access_token"), {});
};
// Adjustments api
export const getStockAdjustments = (data) => {
  return doGet(
    "/stock-adjustments",
    localStorage.getItem("access_token"),
    data
  );
};
export const createStockAdjustments = (data) => {
  return doPost(
    "/stock-adjustments",
    data,
    localStorage.getItem("access_token")
  );
};
// Transfers api
export const getStockTransfers = () => {
  return doGet("/stock-transfers", localStorage.getItem("access_token"), {});
};
// Transfer products from one branch to another (company owner only).
export const createStockTransfers = (data) => {
  return doPost(
    "/stock-transfers/store",
    data,
    localStorage.getItem("access_token")
  );
};
// Request products transfer from one branch to another.
export const stockTransfersRequest = (data) => {
  return doPost(
    "/stock-transfers/request",
    data,
    localStorage.getItem("access_token")
  );
};
export const approveStockTransfersRequest = (data) => {
  return doPost(
    "/stock-transfers/" + data.id + "/approve",
    data,
    localStorage.getItem("access_token")
  );
};
export const cancelRejectStockTransfersRequest = (data) => {
  return doPost(
    "/stock-transfers/" + data.get("id") + "/cancel-reject",
    data,
    localStorage.getItem("access_token")
  );
};
export const updateStockTransfers = (data) => {
  return doPost(
    "/stock-transfers/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const getProductStockByBranch = (branchId) => {
  return doGet(
    "/branches/" + branchId + "/products/stock",
    localStorage.getItem("access_token"),
    {}
  );
};
// =====================  Inventory API's end ===========================

// ---------------------  Customers API's -------------------------------
export const getCustomers = (data) => {
  return doGet("/customer", localStorage.getItem("access_token"), data);
};
export const getSingleCustomer = (id) => {
  return doGet("/customer/" + id, localStorage.getItem("access_token"));
};
export const createCustomer = (data) => {
  return doPost("/customer", data, localStorage.getItem("access_token"));
};
export const updateCustomer = (data) => {
  return doPost(
    "/customer/" + data.id + "/update",
    data,
    localStorage.getItem("access_token")
  );
};
export const deleteCustomer = (id) => {
  return doDelete("/customer/" + id, {}, localStorage.getItem("access_token"));
};
export const getCustomerTransactions = (id, data) => {
  return doGet(
    "/customer-transactions/" + id,
    localStorage.getItem("access_token"),
    data
  );
};
// =====================  Customers API's end ===========================

// ---------------------  Sale Invoice API's -------------------------------
export const getSaleInvoices = (data) => {
  return doGet("/sale-invoices", localStorage.getItem("access_token"), data);
};
export const getSingleSaleInvoice = (id) => {
  return doGet("/sale-invoice/" + id, localStorage.getItem("access_token"));
};
export const createSaleInvoice = (data) => {
  return doPost(
    "/create-sale-invoice",
    data,
    localStorage.getItem("access_token")
  );
};
export const getSaleInvoicesCount = () => {
  return doGet("/sale-invoices/counts", localStorage.getItem("access_token"));
};
export const ChangeSaleInvoiceStatus = (data) => {
  return doPost(
    "/sale-invoice-status-update/" + data.get("id"),
    {},
    localStorage.getItem("access_token")
  );
};
export const registerSaleInvoicePayment = (data) => {
  return doPost(
    "/register-sale-invoice-payment",
    data,
    localStorage.getItem("access_token")
  );
};
export const getInvoicePaymentsDetails = (id) => {
  return doGet(
    "/get-sale-invoice-payments/" + id,
    localStorage.getItem("access_token")
  );
};

// =====================  Customers API's end ===========================
